import React from "react";

const posts = [
  {
    id: 1,
    title: "Newton Principle Agency Corp.",
    href: "https://www.linkedin.com/in/francisco-castillo-218a67212/",
    description:
      "Designed Python web crawlers (Selenium, Scrapy, Beautiful Soup) for efficient data extraction, implemented advanced algorithms, and integrated databases/APIs to automate information flow, enabling comprehensive analytics.",
    date: "Feb 2020 - Present",
    datetime: "2020-03-16",
    category: { title: "Software Engineer", href: "#" },
    author: {
      name: "Washington, DC",
      role: "View website",
      href: "#",
      imageUrl:
        "https://media.licdn.com/dms/image/C4E0BAQGOHvrZJ0nnSQ/company-logo_200_200/0/1670956826962?e=2147483647&v=beta&t=Zt9CRX8sofxeB0LUaEoM21yzOStz8kSL_fF5Vu9vnKU",
    },
  },
  {
    id: 2,
    title: "University of North Texas",
    href: "#https://www.linkedin.com/in/francisco-castillo-218a67212/",
    description:
      "Researched machine learning in testing and contributed to a Python-based social media webcrawler project focused on detecting illicit drug sales on Twitter. Engineered a machine learning model for automated risk assessment, with findings published at The Intelligent Cybersecurity Conference.",
    date: "May - July 2023",
    datetime: "2020-03-16",
    category: { title: "Testing and Machine Learning Research Intern", href: "#" },
    author: {
      name: "Denton, TX",
      role: "View website",
      href: "https://computerscience.engineering.unt.edu/reu/tamale",
      imageUrl:
        "https://th.bing.com/th/id/OIP.grvhKGn81xs6t4mpcMEcSAAAAA?w=180&h=180&c=7&r=0&o=5&pid=1.7",
    },
  },
  {
    id: 3,
    title: "Southern Methodist University",
    href: "#https://www.linkedin.com/in/francisco-castillo-218a67212/",
    description:
      "Conducted a data-driven research project on the Dallas County voting system using Python and ArcGIS. Developed interactive maps to visually present findings, enhancing accessibility for a wider audience.",
    date: "June - July 2022",
    datetime: "2020-03-16",
    category: {
      title: "Data Science Research Intern",
      href: "",
    },
    author: {
      name: "Dallas, TX",
      role: "View website",
      href: "https://www.smu.edu/provost/data-science-institute/reu/2022-reu",
      imageUrl:
        "https://www.southwestern.edu/live/image/gid/169/width/1000/34205_SMU_logo.rev.1569874285.png",
    },
  },
  // More posts...
];

export default function Experience() {
  return (
    <section id="experience">
 <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Experience
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Below you'll find my various job and intership experience.
          </p>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article
              key={post.id}
              className="flex max-w-xl flex-col items-start justify-between"
            >
              <div className="flex items-center gap-x-4 text-xs">
                <time dateTime={post.datetime} className="text-gray-500">
                  {post.date}
                </time>
                <a
                  href={post.category.href}
                  className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                >
                  {post.category.title}
                </a>
              </div>
              <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                  {post.description}
                </p>
              </div>
              <div className="relative mt-8 flex items-center gap-x-4">
                <img
                  src={post.author.imageUrl}
                  alt=""
                  className="h-10 w-10 rounded-full bg-gray-50"
                />
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <a href={post.author.href}>
                      <span className="absolute inset-0" />
                      {post.author.name}
                    </a>
                  </p>
                  <p className="text-gray-600">{post.author.role}</p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
    </section>
   
  );
}
