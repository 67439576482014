import React from "react";
import Header from "../components/Header";
import Experience from "../components/Experience";
import Project from "../components/Project";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div className="bg-white">
      <Header />
      <div className="min-h-screen flex flex-col lg:flex-row items-center justify-center">
        <div className="mb-4 lg:mr-4">
          <div className="mx-auto max-w-2xl">
            <div className="hidden sm:mb-8 sm:flex sm:justify-center">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                Visit my LinkedIn account.{" "}
                <a
                  href="https://www.linkedin.com/in/francisco-castillo-218a67212/"
                  target="_blank"
                  className="font-semibold text-green-600"
                >
                  <span className="absolute inset-0" aria-hidden="true" />
                  View more <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Francisco Castillo personal portfolio
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Elevating Possibilities through Expert Software Engineering:
                Precision, Innovation, and Results.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link
                  to="/projects"
                  className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  View Work
                </Link>
                <a
                  href="https://github.com/FranciscoCastillo41"
                  target="_blank"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  GitHub <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Experience />
      
      <Project />
      
    </div>
  );
}
