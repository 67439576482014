import React from "react";
import Header from "../components/Header";
import { CheckIcon } from "@heroicons/react/20/solid";

const includedFeatures = [
  "Private forum access",
  "Member resources",
  "Entry to annual conference",
  "Official member t-shirt",
];

const mern_cafe_technologies = [
  "React.js",
  "MongoDB",
  "Express",
  "Node.js",
  "Redux Toolkit",
  "Bootstrap5",
];

const blur_dating_technologies = [
  "Java",
  "Android Studio",
  "Firebase",
  "Firestore",
];

const event_management_technologies = [
  "AWS Cloud Services",
  "React.js",
  "GraphQL",
  "Amplify",
  "Material UI",
];

export default function Projects() {
  return (
    <div>
      <Header />
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Check out my work!
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et
              quasi iusto modi velit ut non voluptas in. Explicabo id ut
              laborum.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <a href="https://github.com/FranciscoCastillo41/Cafe-Feedback" target="_target" className="text-2xl font-bold tracking-tight text-gray-900">
                Dora's Cafe Feedback Web App
              </a>
              <p className="mt-6 text-base leading-7 text-gray-600">
                Developed a full-stack web app using React, Node.js, and
                MongoDB, with Firebase for authentication and Redux Toolkit for
                state management. Integrated Google OAuth and created APIs for
                student cafeteria feedback. Implemented a dedicated admin
                account for efficient monitoring.
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-green-600">
                  Technologies used
                </h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
              >
                {mern_cafe_technologies.map((technologies) => (
                  <li key={technologies} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-green-600"
                      aria-hidden="true"
                    />
                    {technologies}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <a href="https://github.com/renrenlion/BlurDatingApp" target="_target" className="text-2xl font-bold tracking-tight text-gray-900">
                The Blur Dating App
              </a>
              <p className="mt-6 text-base leading-7 text-gray-600">
                Collaboratively crafted a Java-based Android dating app in
                Android Studio, emphasizing mobile development on the Android
                platform. Integrated Firebase/Firestore for secure cloud-based
                data management, real-time updates, and premium account
                payments, featuring a distinctive photo-blurring matching
                system. Implemented UML models and documentation to ensure a
                robust and secure user experience, particularly in architecture
                and payment processing.
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-green-600">
                  Technologies used
                </h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
              >
                {blur_dating_technologies.map((technologies) => (
                  <li key={technologies} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-green-600"
                      aria-hidden="true"
                    />
                    {technologies}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <a href="https://github.com/FranciscoCastillo41/Event-Management-System" target="_target" className="text-2xl font-bold tracking-tight text-gray-900">
                Event Management System
              </a>
              <p className="mt-6 text-base leading-7 text-gray-600">
                Built an Event Management Web Application with React.js and
                Material UI for the frontend. Integrated AWS Cloud Services,
                leveraging AWS Cognito for authentication and DynamoDB for
                scalable data storage. Implemented GraphQL APIs for optimized
                data querying and designed a robust database schema to enhance
                event management efficiency.
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-green-600">
                  Technologies used
                </h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
              >
                {event_management_technologies.map((technologies) => (
                  <li key={technologies} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-green-600"
                      aria-hidden="true"
                    />
                    {technologies}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
